import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Sparse Matrix`}</h1>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` gathers a `}<a parentName="li" {...{
          "href": "/sparse-matrix",
          "title": "sparse matrix"
        }}>{`sparse matrix`}</a>{` of `}<a parentName="li" {...{
          "href": "/votes",
          "title": "votes"
        }}>{`votes`}</a>{`, as not every `}<a parentName="li" {...{
          "href": "/participant",
          "title": "participant"
        }}>{`participant`}</a>{` will vote on every `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{`.`}</li>
      <li parentName="ul">{`Thus, `}<a parentName="li" {...{
          "href": "/Algorithms",
          "title": "👾 Algorithms"
        }}>{`👾 Algorithms`}</a>{` used to analyze the data must be robust to sparsity.`}</li>
      <li parentName="ul">{`In practice, here's an entire `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{`, from "`}<strong parentName="li">{`Bowling Green Daily News`}</strong>{` Bowling Green, Kentucky, USA", found in the `}<a parentName="li" {...{
          "href": "/Case-studies",
          "title": "⚗️ Case studies"
        }}>{`⚗️ Case studies`}</a>{`. You can see every single `}<a parentName="li" {...{
          "href": "/vote",
          "title": "vote"
        }}>{`vote`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Each row is a participant's voting record`}</li>
          <li parentName="ul">{`Each column is a `}<a parentName="li" {...{
              "href": "/comment",
              "title": "comment"
            }}>{`comment`}</a>{``}</li>
          <li parentName="ul">{`Blue represents an agree, red a disagree, and yellow a pass or 'did not see'.`}</li>
          <li parentName="ul">{`Many participants in `}<a parentName="li" {...{
              "href": "/Polis",
              "title": "Polis"
            }}>{`Polis`}</a>{` conversations will not see many comments. This does not prevent dimensionality reduction and clustering `}<a parentName="li" {...{
              "href": "/Algorithms",
              "title": "👾 Algorithms"
            }}>{`👾 Algorithms`}</a>{` from making meaning by finding patterns`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      